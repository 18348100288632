import React from 'react';
import Datapoints from './General/Datapoints';
import BackgroundColor from './General/BackgroundColor';
import SoundRange from './General/SoundRange';
import Height from './General/Height';
import BarMargin from "./Bar/BarMargin";
import LineColor from "./General/LineColor";
import LineWeight from "./General/LineWeight";
import BarType from "./Bar/BarType";
import LineType from "./Line/LineType";
import LineFill from "./Line/LineFill";
import FillColor from "./General/BarColor";
import LineCircleSize from "./Line/LineCircleSize";
import LineCircleShow from "./Line/LineCircleShow";
import Clipping from "./General/Clipping";
import Attack from "./General/Attack";

const Selection = () => {
    const aSettings = localStorage.getItem('settings') === "Advanced";

    // Define common styles using theme values, if necessary
    const commonStyles = {
        margin: 1, // Example, adjust according to your ThemeContext structure
        minWidth: '120px',
    };

    return (
        <>
            <div className="s-container">
                <BarType style={commonStyles} />
                <BarMargin style={commonStyles} />
            </div>
            <div className="s-container">
                <LineType style={commonStyles} />
                <LineFill style={commonStyles} />
                {aSettings && <LineCircleSize style={commonStyles} />}
                <LineCircleShow style={commonStyles} />
            </div>
            <div className="s-container">
                <Datapoints style={commonStyles} />
                {aSettings && <Attack style={commonStyles} />}
                <SoundRange style={commonStyles} />
                <Height style={commonStyles} />
                {aSettings && <>
                    <Clipping style={commonStyles} />
                    <LineWeight style={commonStyles} />
                    <LineColor />
                </>}
                <FillColor />
                <BackgroundColor />
            </div>
        </>
    );
};

export default Selection;