export async function getYtLink(id, done) {
    if (id !== null && id !== undefined) {
        if (localStorage.getItem("ytID") === id) {
            done(null, { mp3: localStorage.getItem("ytlink"), title: localStorage.getItem('musicName') });
        } else {

            let xhr = new XMLHttpRequest();

            let url = "https://youtube.michaelbelgium.me/api/converter/convert?api_token=a6yTCDXhocWl43LRvrzazsBxvnYPo0vZL4Dm0788iJQPzzTep7Ns2qqUMsDQ&url=https://www.youtube.com/watch?v=" + id;

            xhr.open("POST", `${url}`, true);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        localStorage.setItem("ytID", id);
                        done(null, { mp3: JSON.parse(xhr.responseText).file, title: JSON.parse(xhr.responseText).title });
                    } else {
                        done(JSON.parse(xhr.response));
                    }
                }
            };
            xhr.onerror = () => {
                done(xhr.response);
            };

            xhr.send(`u=${id}`);
        }
    }
}