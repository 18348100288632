import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import SvgIcon from '@mui/material/SvgIcon';
import { createSvgIcon } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 360,
    bgcolor: '#111',
    boxShadow: 24,
    p: 3,
    borderRadius: '60px',
    paddingBottom: '32px',
};

const PwaModal = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const ShareIcon = createSvgIcon(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><path d="M30.3 13.7L25 8.4l-5.3 5.3-1.4-1.4L25 5.6l6.7 6.7z" /><path d="M24 7h2v21h-2z" /><path d="M35 40H15c-1.7 0-3-1.3-3-3V19c0-1.7 1.3-3 3-3h7v2h-7c-.6 0-1 .4-1 1v18c0 .6.4 1 1 1h20c.6 0 1-.4 1-1V19c0-.6-.4-1-1-1h-7v-2h7c1.7 0 3 1.3 3 3v18c0 1.7-1.3 3-3 3z" /></svg>,
        'Home',
    );

    return (
        <div>
            <span style={{ cursor: "pointer" }} className="a-footer" onClick={handleOpen}><p className="footer-p">Mobile</p></span>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} display="flex"
                    justifyContent="center"
                    alignItems="center">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Tap<SvgIcon component={ShareIcon} fontSize="large" sx={{ marginBottom: -0.5 }} />&nbsp; then "Add to Home Screen"
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}

export default PwaModal;