import React, { useState } from 'react'
import Button from "@mui/material/Button";


const SwitchMicMusic = () => {
    const [micOrSound, setMicOrSound] = useState(localStorage.getItem('micOrSound') === null ? "mic" : localStorage.getItem('micOrSound'));

    const changeInput = () => {
        setMicOrSound("mic");
        localStorage.setItem('micOrSound', "mic");
        global.refreshCanvas();
    };
    if (micOrSound === "toneGenerator" || micOrSound === "compareInputs" || micOrSound === "sound") {
        return (
            <label className="right-when-large">
                <Button className="bottom-button" style={{ marginBottom: "20px" }} variant="outlined"
                    component="span" onClick={changeInput}>
                    Switch back to Microphone Input
                </Button>
                &nbsp;
            </label>
        )
    } else {
        return (
            <></>
        )
    }

};

export default SwitchMicMusic;