import React from 'react'
import { Link } from "react-router-dom";

const Error = () => {
    return (
        <>
            <p style={{ marginLeft: "20px", color: "white" }}>Error: Page not found!</p>
            <Link style={{ fontSize: "24px", marginLeft: "20px", color: "white" }} to="/">Oh no, go back</Link>

        </>
    )
};

export default Error;