import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";

const LineFill = (style) => {
    const [checked, setChecked] = React.useState(localStorage.getItem('lineFill') === null ? false : 'true' === localStorage.getItem('lineFill'));
    const vis = localStorage.getItem('vis') === null ? 'bar' : localStorage.getItem('vis');

    const handleChange = (event) => {
        setChecked(event.target.checked);
        localStorage.setItem('lineFill', event.target.checked)
    };

    if (vis === 'line' || vis === 'line_circle') {
        return (
            <FormControl className={"all-form-inputs"} sx={style}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <FormControlLabel sx={style} style={{ color: "#b9b9bb" }}
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={handleChange}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Fill"
                    />
                </Box>
            </FormControl>
        );
    } else {
        return (<></>);
    }
};


export default LineFill;