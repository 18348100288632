import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Slider from '@mui/material/Slider';
import Box from "@mui/material/Box";


const SoundRange = (style) => {
    const first = localStorage.getItem('unscaledBarRange') === null ? 0 : Number(localStorage.getItem('unscaledBarRange').split(",")[0]);
    const last = localStorage.getItem('unscaledBarRange') === null ? 50 : Number(localStorage.getItem('unscaledBarRange').split(",")[1]);

    const [value, setValue] = React.useState([first, last]);

    const handleChange = (event, newValue) => {
        console.log(newValue);
        if (newValue[0] - newValue[1] > 1 || newValue[0] - newValue[1] < -1) {
            setValue(newValue);
            if (localStorage.getItem('unscaledBarRange') !== value) {
                localStorage.setItem('unscaledBarRange', value);
                localStorage.setItem('barRange', value.map(val => calculateValue(val)));
            }
        }
    };

    function calculateValue(value) {
        const val = Math.round(1.04713 ** value * 10) / 10;
        return val;
    }

    const marks = [
        {
            value: 1,
            label: '1',
        },
        {
            value: 100,
            label: '100',
        },
    ];
    if (localStorage.getItem("micOrSound") !== "toneGenerator" || localStorage.getItem("pinkNoise") === "true") {
        return (
            <FormControl className={"all-form-inputs"} sx={style}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                        Frequency Range
                    </InputLabel>
                    <Slider style={{ marginTop: "24px" }}
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        scale={calculateValue}
                        marks={marks}
                    />
                </Box>
            </FormControl>
        );
    } else {
        return <></>
    }
};


export default SoundRange;