import { useState } from "react";
import useWindowDimensions from "./helpers";
import { isMobile } from 'react-device-detect';

const LandingPageWave = () => {
    const [y, setY] = useState(0);
    const [listener, setListener] = useState(false);
    const { height } = useWindowDimensions();

    const handleMouseMove = (evt) => {
        setY(evt.clientY / height * 42);
    }

    document.removeEventListener('mousemove', handleMouseMove);
    if (!isMobile && !listener) {
        setListener(true);
        document.addEventListener('mousemove', handleMouseMove);
    }



    return (
        <svg style={{ minHeight: "210px", height: "100%", width: "auto", marginLeft: "-15vh" }} id="visual" viewBox="0 0 1500 100" width="1500" height="100" xmlns="http://www.w3.org/2000/svg" version="1.1" fillOpacity="0.21">
            <path style={{ transform: `translate(${y * 1}px,0px)` }} d="M0 48L10.8 50.2C21.7 52.3 43.3 56.7 65 58.8C86.7 61 108.3 61 130.2 57.5C152 54 174 47 195.8 44.7C217.7 42.3 239.3 44.7 261 45.3C282.7 46 304.3 45 326 41.5C347.7 38 369.3 32 391.2 28.5C413 25 435 24 456.8 27.2C478.7 30.3 500.3 37.7 522 40.5C543.7 43.3 565.3 41.7 587 40.2C608.7 38.7 630.3 37.3 652 35.3C673.7 33.3 695.3 30.7 717.2 33.2C739 35.7 761 43.3 782.8 48C804.7 52.7 826.3 54.3 848 52.5C869.7 50.7 891.3 45.3 913 41.3C934.7 37.3 956.3 34.7 978 36C999.7 37.3 1021.3 42.7 1043.2 40.3C1065 38 1087 28 1108.8 24.5C1130.7 21 1152.3 24 1174 30.2C1195.7 36.3 1217.3 45.7 1239 44.5C1260.7 43.3 1282.3 31.7 1304.2 32.2C1326 32.7 1348 45.3 1369.8 44.8C1391.7 44.3 1413.3 30.7 1435 29.3C1456.7 28 1478.3 39 1489.2 44.5L1500 50L1500 101L1489.2 101C1478.3 101 1456.7 101 1435 101C1413.3 101 1391.7 101 1369.8 101C1348 101 1326 101 1304.2 101C1282.3 101 1260.7 101 1239 101C1217.3 101 1195.7 101 1174 101C1152.3 101 1130.7 101 1108.8 101C1087 101 1065 101 1043.2 101C1021.3 101 999.7 101 978 101C956.3 101 934.7 101 913 101C891.3 101 869.7 101 848 101C826.3 101 804.7 101 782.8 101C761 101 739 101 717.2 101C695.3 101 673.7 101 652 101C630.3 101 608.7 101 587 101C565.3 101 543.7 101 522 101C500.3 101 478.7 101 456.8 101C435 101 413 101 391.2 101C369.3 101 347.7 101 326 101C304.3 101 282.7 101 261 101C239.3 101 217.7 101 195.8 101C174 101 152 101 130.2 101C108.3 101 86.7 101 65 101C43.3 101 21.7 101 10.8 101L0 101Z" fill="#522f16"></path>
            <path style={{ transform: `translate(${y * 0.5}px,0px)` }} d="M0 67L10.8 64.5C21.7 62 43.3 57 65 54.7C86.7 52.3 108.3 52.7 130.2 51.8C152 51 174 49 195.8 45C217.7 41 239.3 35 261 35.3C282.7 35.7 304.3 42.3 326 48.3C347.7 54.3 369.3 59.7 391.2 62C413 64.3 435 63.7 456.8 61.7C478.7 59.7 500.3 56.3 522 51.8C543.7 47.3 565.3 41.7 587 39.7C608.7 37.7 630.3 39.3 652 44.5C673.7 49.7 695.3 58.3 717.2 62.7C739 67 761 67 782.8 62.8C804.7 58.7 826.3 50.3 848 45.2C869.7 40 891.3 38 913 41.7C934.7 45.3 956.3 54.7 978 53.7C999.7 52.7 1021.3 41.3 1043.2 39.5C1065 37.7 1087 45.3 1108.8 52.3C1130.7 59.3 1152.3 65.7 1174 66C1195.7 66.3 1217.3 60.7 1239 55.7C1260.7 50.7 1282.3 46.3 1304.2 42.5C1326 38.7 1348 35.3 1369.8 35.5C1391.7 35.7 1413.3 39.3 1435 41.8C1456.7 44.3 1478.3 45.7 1489.2 46.3L1500 47L1500 101L1489.2 101C1478.3 101 1456.7 101 1435 101C1413.3 101 1391.7 101 1369.8 101C1348 101 1326 101 1304.2 101C1282.3 101 1260.7 101 1239 101C1217.3 101 1195.7 101 1174 101C1152.3 101 1130.7 101 1108.8 101C1087 101 1065 101 1043.2 101C1021.3 101 999.7 101 978 101C956.3 101 934.7 101 913 101C891.3 101 869.7 101 848 101C826.3 101 804.7 101 782.8 101C761 101 739 101 717.2 101C695.3 101 673.7 101 652 101C630.3 101 608.7 101 587 101C565.3 101 543.7 101 522 101C500.3 101 478.7 101 456.8 101C435 101 413 101 391.2 101C369.3 101 347.7 101 326 101C304.3 101 282.7 101 261 101C239.3 101 217.7 101 195.8 101C174 101 152 101 130.2 101C108.3 101 86.7 101 65 101C43.3 101 21.7 101 10.8 101L0 101Z" fill="#75401a"></path>
            <path style={{ transform: `translate(${y * 0.25}px,0px)` }} d="M0 64L10.8 62.8C21.7 61.7 43.3 59.3 65 59.3C86.7 59.3 108.3 61.7 130.2 61.3C152 61 174 58 195.8 60.8C217.7 63.7 239.3 72.3 261 75.2C282.7 78 304.3 75 326 72.8C347.7 70.7 369.3 69.3 391.2 66.7C413 64 435 60 456.8 58.5C478.7 57 500.3 58 522 58.8C543.7 59.7 565.3 60.3 587 61.8C608.7 63.3 630.3 65.7 652 64.3C673.7 63 695.3 58 717.2 57.8C739 57.7 761 62.3 782.8 61.5C804.7 60.7 826.3 54.3 848 55.3C869.7 56.3 891.3 64.7 913 69.3C934.7 74 956.3 75 978 73C999.7 71 1021.3 66 1043.2 61.7C1065 57.3 1087 53.7 1108.8 53.5C1130.7 53.3 1152.3 56.7 1174 60.2C1195.7 63.7 1217.3 67.3 1239 66.5C1260.7 65.7 1282.3 60.3 1304.2 58.8C1326 57.3 1348 59.7 1369.8 60.5C1391.7 61.3 1413.3 60.7 1435 63.5C1456.7 66.3 1478.3 72.7 1489.2 75.8L1500 79L1500 101L1489.2 101C1478.3 101 1456.7 101 1435 101C1413.3 101 1391.7 101 1369.8 101C1348 101 1326 101 1304.2 101C1282.3 101 1260.7 101 1239 101C1217.3 101 1195.7 101 1174 101C1152.3 101 1130.7 101 1108.8 101C1087 101 1065 101 1043.2 101C1021.3 101 999.7 101 978 101C956.3 101 934.7 101 913 101C891.3 101 869.7 101 848 101C826.3 101 804.7 101 782.8 101C761 101 739 101 717.2 101C695.3 101 673.7 101 652 101C630.3 101 608.7 101 587 101C565.3 101 543.7 101 522 101C500.3 101 478.7 101 456.8 101C435 101 413 101 391.2 101C369.3 101 347.7 101 326 101C304.3 101 282.7 101 261 101C239.3 101 217.7 101 195.8 101C174 101 152 101 130.2 101C108.3 101 86.7 101 65 101C43.3 101 21.7 101 10.8 101L0 101Z" fill="#9b521e"></path>
            <path style={{ transform: `translate(${y * 0.13}px,0px)` }} d="M0 91L10.8 88.3C21.7 85.7 43.3 80.3 65 76.3C86.7 72.3 108.3 69.7 130.2 72.5C152 75.3 174 83.7 195.8 84.5C217.7 85.3 239.3 78.7 261 77.2C282.7 75.7 304.3 79.3 326 78.8C347.7 78.3 369.3 73.7 391.2 74.5C413 75.3 435 81.7 456.8 85C478.7 88.3 500.3 88.7 522 85.7C543.7 82.7 565.3 76.3 587 73C608.7 69.7 630.3 69.3 652 72.3C673.7 75.3 695.3 81.7 717.2 83.5C739 85.3 761 82.7 782.8 80.2C804.7 77.7 826.3 75.3 848 75.8C869.7 76.3 891.3 79.7 913 80.5C934.7 81.3 956.3 79.7 978 81.2C999.7 82.7 1021.3 87.3 1043.2 87.2C1065 87 1087 82 1108.8 79.5C1130.7 77 1152.3 77 1174 77C1195.7 77 1217.3 77 1239 77.8C1260.7 78.7 1282.3 80.3 1304.2 82.2C1326 84 1348 86 1369.8 87.2C1391.7 88.3 1413.3 88.7 1435 89.2C1456.7 89.7 1478.3 90.3 1489.2 90.7L1500 91L1500 101L1489.2 101C1478.3 101 1456.7 101 1435 101C1413.3 101 1391.7 101 1369.8 101C1348 101 1326 101 1304.2 101C1282.3 101 1260.7 101 1239 101C1217.3 101 1195.7 101 1174 101C1152.3 101 1130.7 101 1108.8 101C1087 101 1065 101 1043.2 101C1021.3 101 999.7 101 978 101C956.3 101 934.7 101 913 101C891.3 101 869.7 101 848 101C826.3 101 804.7 101 782.8 101C761 101 739 101 717.2 101C695.3 101 673.7 101 652 101C630.3 101 608.7 101 587 101C565.3 101 543.7 101 522 101C500.3 101 478.7 101 456.8 101C435 101 413 101 391.2 101C369.3 101 347.7 101 326 101C304.3 101 282.7 101 261 101C239.3 101 217.7 101 195.8 101C174 101 152 101 130.2 101C108.3 101 86.7 101 65 101C43.3 101 21.7 101 10.8 101L0 101Z" fill="#c36522"></path>
        </svg>
    )
}

export default LandingPageWave;