import React, { useState } from 'react'
import FormControl from '@mui/material/FormControl';
import Box from "@mui/material/Box";
import { WhiteTextField } from "../WhiteTextField";


const BarMargin = (style) => {

    const [margin, setMargin] = useState(localStorage.getItem('barMargin') === null ? 5 : localStorage.getItem('barMargin'));
    const type = localStorage.getItem('type') === null ? 'bar' : localStorage.getItem('type');

    const updateMargin = (e) => {
        try {
            if (e.target.value < 0) {
                setMargin(0);
                localStorage.setItem('barMargin', '0');
            } else if (e.target.value > 1000) {
                setMargin(1000);
                localStorage.setItem('barMargin', '1000');
            } else {
                setMargin(parseInt(e.target.value));
                if (e.target.value === '') {
                    localStorage.setItem('barMargin', '0');
                } else {
                    localStorage.setItem('barMargin', '' + parseInt(e.target.value));
                }
            }
        } catch (e) {
            setMargin(0);
            localStorage.setItem('barMargin', '0');
        }
    };

    if (type === 'bar') {
        return (
            <FormControl sx={style}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, minWidth: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <WhiteTextField className={"all-form-inputs"} variant="standard" type="number" id="outlined-number" label="Bar Margin"
                        value={margin}
                        onChange={updateMargin} InputLabelProps={{
                            shrink: true,
                        }} />
                </Box>
            </FormControl>
        );
    } else {
        return (<></>);
    }
}

export default BarMargin;