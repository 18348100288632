import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ColorPicker from 'material-ui-color-picker';
import Box from "@mui/material/Box";

const BackgroundColor = () => {
    let color = localStorage.getItem('bcolor') ?? '#000000';

    const setColor = (newColor) => {
        if (newColor !== undefined && localStorage.getItem('bcolor') !== newColor) {
            localStorage.setItem('bcolor', newColor);
        }
    };

    return (
        <FormControl
            sx={{
                '& .MuiInput-underline:before': {
                    borderBottomColor: '#fff8!important', // Semi-transparent underline
                },
                '& .MuiInput-underline:after': {
                    borderBottomColor: '#747477!important', // Solid underline on hover
                },
                ':hover': {
                    // Define hover styles for FormControl here
                    '& .MuiInput-underline:before': {
                        borderBottomColor: '#747477!important', // Example hover style
                    },
                },
                margin: 1, // Example, adjust according to your ThemeContext structure
                minWidth: '120px',
            }}
            className={"all-form-inputs"}
        >
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, minWidth: '25ch' },
                    // No need for noValidate and autoComplete props on Box
                }}
            >
                <InputLabel
                    sx={{
                        // Define styles for InputLabel if needed
                    }}
                    shrink
                    id="demo-simple-select-placeholder-label-label"
                >
                    Background Color
                </InputLabel>
                <ColorPicker
                    name='color'
                    className={"all-form-inputs-without-margin"}
                    defaultValue={color}
                    onChange={setColor}
                />
            </Box>
        </FormControl>
    );
};

export default BackgroundColor;