import { useState, useEffect } from 'react';

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
};

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


export function unpackSettings(data) {

    const res = data.replace("{", "").replace("}", "").split(":");

    const set = res.map(r => {
        if (!r.includes(",")) {
            return `\"${r}\"`;
        }
        const sub = r.split(",");
        if (sub.length === 2) {
            return `\"${sub[0]}\",\"${sub[1]}\"`
        } else {
            return `\"${sub[0]},${sub[1]}\",\"${sub[2]}\"`
        }
    }).join(':');

    const setting = `{${set}}`.replace(/§/g, "#")

    const settings = JSON.parse(setting);

    const range = settings.r.split(",").map(val => Math.round(1.04713 ** Number(val) * 10) / 10).join();

    localStorage.setItem('micOrSound', settings.m);
    localStorage.setItem('type', settings.t);
    localStorage.setItem('vis', settings.v);
    localStorage.setItem('barMultiple', settings.b);
    localStorage.setItem('attack', settings.a);
    localStorage.setItem('unscaledBarRange', settings.r);
    localStorage.setItem('barRange', range);
    localStorage.setItem('height', settings.h);
    localStorage.setItem('clipping', settings.c);
    localStorage.setItem('lineWeight', settings.w);
    localStorage.setItem('linecolor', settings.l);
    localStorage.setItem('fillcolor', settings.f);
    localStorage.setItem('bcolor', settings.o);
    localStorage.setItem('barMargin', settings.g);
    localStorage.setItem('lineFill', settings.n);
    localStorage.setItem('lineCircleSize', settings.s);
    localStorage.setItem('showLineCircle', settings.i);
}

export function createSettings() {
    const settings = {
        "m": localStorage.getItem('micOrSound') === null ? "mic" : localStorage.getItem('micOrSound'),
        "t": localStorage.getItem('type') === null ? "bar" : localStorage.getItem('type'),
        "v": localStorage.getItem('vis') === null ? "bars" : localStorage.getItem('vis'),
        "b": localStorage.getItem('barMultiple') === null ? "9" : localStorage.getItem('barMultiple'),
        "a": localStorage.getItem('attack') === null ? "0.8" : localStorage.getItem('attack'),
        "r": localStorage.getItem('unscaledBarRange') === null ? "0,50" : localStorage.getItem('unscaledBarRange'),
        "h": localStorage.getItem('height') === null ? "60" : localStorage.getItem('height'),
        "c": localStorage.getItem('clipping') === null ? "0" : localStorage.getItem('clipping'),
        "w": localStorage.getItem('lineWeight') === null ? "1" : localStorage.getItem('lineWeight'),
        "l": localStorage.getItem('linecolor') === null ? "#fff" : localStorage.getItem('linecolor'),
        "f": localStorage.getItem('fillcolor') === null ? "#fff" : localStorage.getItem('fillcolor'),
        "o": localStorage.getItem('bcolor') === null ? "#000" : localStorage.getItem('bcolor'),
        "g": localStorage.getItem('barMargin') === null ? "5" : localStorage.getItem('barMargin'),
        "n": localStorage.getItem('lineFill') === null ? "false" : localStorage.getItem('lineFill'),
        "s": localStorage.getItem('lineCircleSize') === null ? "50" : localStorage.getItem('lineCircleSize'),
        "i": localStorage.getItem('showLineCircle') === null ? "false" : localStorage.getItem('showLineCircle'),
    }

    return JSON.stringify(settings).replace(/"/g, '').replace(/#/g, "§");
}