import React, { useState } from 'react'
import FormControl from '@mui/material/FormControl';
import Box from "@mui/material/Box";
import { WhiteTextField } from "../WhiteTextField";


const LineCircleSize = (style) => {

    const [lCircleSize, setLCircleSize] = useState(localStorage.getItem('lineCircleSize') === null ? 50 : localStorage.getItem('lineCircleSize'));
    const vis = localStorage.getItem('vis') === null ? 'bars' : localStorage.getItem('vis');

    const updateMargin = (e) => {
        try {
            if (e.target.value < 0) {
                setLCircleSize(0);
                localStorage.setItem('lineCircleSize', '0');
            } else if (e.target.value > 1000) {
                setLCircleSize(1000);
                localStorage.setItem('lineCircleSize', '1000');
            } else {
                setLCircleSize(parseInt(e.target.value));
                if (e.target.value === '') {
                    localStorage.setItem('lineCircleSize', '0');
                } else {
                    localStorage.setItem('lineCircleSize', '' + parseInt(e.target.value));
                }
            }
        } catch (e) {
            setLCircleSize(0);
            localStorage.setItem('lineCircleSize', '1');
        }
    };

    if (vis === 'line_circle' || vis === 'colorful_circle') {
        return (
            <FormControl sx={style}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, minWidth: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <WhiteTextField className={"all-form-inputs"} variant="standard" type="number" id="standard-basic"
                        label="Line CircleSize"
                        value={lCircleSize}
                        onChange={updateMargin} />
                </Box>
            </FormControl>
        )
    } else {
        return (<></>);
    }
};

export default LineCircleSize;