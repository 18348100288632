import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

export const WhiteTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-input': {
      color: '#fff!important', // Text color
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#fff8!important', // Semi-transparent underline
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#90caf9!important', // Solid underline on hover
    },
    // If you need to use `!important`, consider using a higher specificity or ensure the styles are not being overridden elsewhere
  }));