import React, { useState } from 'react'
import Button from "@mui/material/Button";

import { styled } from '@mui/material/styles';


const MusicSelect = () => {
    const [fileName, setFileName] = useState(localStorage.getItem('musicName') === null ? "" : localStorage.getItem('musicName'));
    const [micOrSound, setMicOrSound] = useState(localStorage.getItem('micOrSound') === null ? "mic" : localStorage.getItem('micOrSound'));

    const manageChange = (e) => {
        let file = e.target.files[0];
        if (file.type && !file.type.startsWith('audio/')) {
            console.log('File is not an audio file.', file.type, file);
            return;
        }
        setFileName(file.name);
        if (localStorage.getItem('musicData') !== null) {
            URL.revokeObjectURL(localStorage.getItem('musicData'));
        }
        localStorage.setItem('musicData', URL.createObjectURL(file));
        localStorage.setItem('musicName', file.name);
        localStorage.setItem('micOrSound', 'sound');
        localStorage.removeItem('ytlink');
        global.refreshCanvas();
    };


    const Input = styled('input')({
        display: 'none',
    });
    if (localStorage.getItem('type') !== "compareInputs") {
        return (
            <>
                <label htmlFor="contained-button-file" className="right-when-large"
                    style={{ marginBottom: "40px" }}>
                    <Input id="contained-button-file" type="file"
                        onChange={e => manageChange(e)} />
                    <Button className="bottom-button" style={{ marginBottom: "20px", fontWeight: 700 }} variant="contained"
                        component="span">
                        Select Song
                    </Button>
                    &nbsp;
                </label>
            </>
        )
    } else {
        return <></>
    }
};

export default MusicSelect;