import React, { useState } from 'react';
import './App.css';
import Selection from './components/Selection';
import Button from '@mui/material/Button';


import MusicSelect from "./components/MusicSelect";
import SwitchMicMusic from "./components/SwitchMicMusic";
import { usePageVisibility } from 'react-page-visibility';
import FreqSelect from "./components/General/FreqSelect";
import MusicFromLink from "./components/MusicFromLink";
import BehindCanvas from "./components/BehindCanvas";
import AudioPlayer from "./components/AudioPlayer";

import CloseIcon from '@mui/icons-material/Close';

import Alert from "@mui/material/Alert/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import TopSelection from './components/TopSelection';
import useWindowDimensions from './helpers';

const MainApp = () => {
    const [reload, setReload] = useState(0);
    const isVisible = usePageVisibility();
    const [inputs, setInputs] = useState(typeof localStorage.getItem('icw') === "string" ? localStorage.getItem('icw').split(',') : [0, "none", "none"]);
    const { width } = useWindowDimensions();

    const aSettings = localStorage.getItem('settings') === "Advanced";

    window.addEventListener("storage", () => {
        if (localStorage.getItem('micOrSound') === "compareInputs") {
            setInputs(typeof localStorage.getItem('icw') === "string" ? localStorage.getItem('icw').split(',') : [0, "none", "none"]);
        }
    });

    global.refreshCanvas = () => {
        setReload(reload + 1);
    };

    const checkWidth = width > 800;

    const CheckError = () => {

        const [open, setOpen] = React.useState(localStorage.getItem("error") !== null);

        return (
            <Box sx={{ width: '100%' }}>
                <Collapse in={open}>
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpen(false);
                                    localStorage.removeItem("error");
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        {localStorage.getItem("error")}
                    </Alert>
                </Collapse>
            </Box>
        )
    };

    if (isVisible || localStorage.getItem("batteryS") === "false") {
        return (
            <div className="app">
                <div style={{ marginTop: 15, display: "flex", alignItems: "center" }}>
                    <h1 style={{ display: "inline", paddingTop: 0 }}><a style={{ textDecoration: "none", color: "inherit" }} href="https://soundvisualiser.com">Sound&nbsp;Visualizer</a>
                    </h1>{checkWidth &&
                        <div style={{ width: "100%", overflow: "auto" }}>
                            <div style={{ float: "right", marginRight: 25 }}>
                                <TopSelection key={reload} />
                            </div>
                        </div>
                    }
                </div>
                <div className="sticky-canvas" key={reload}>
                    <iframe id="iframe1" src="/visualizer/index.html" title={reload} />
                    <BehindCanvas />
                    <AudioPlayer />
                </div>
                {!checkWidth &&
                    <div className="big-container">
                        <div id="full-screen">
                            <TopSelection key={reload} />
                        </div>
                    </div>
                }
                <div className="big-container">
                    <div id="full-screen">
                        <div className='float-container' style={{ float: "right" }}>
                            <Button className="bottom-button" variant="outlined"
                                style={{ marginBottom: "20px" }}
                                onClick={() => setReload(reload + 1)}>reload
                                Canvas</Button>&nbsp;
                            {aSettings && <>
                                <Button className="bottom-button" variant="outlined"
                                    style={{ marginBottom: "20px" }}
                                    href="/visualizer/index.html" target="_blank"> Open
                                    Visualizer in
                                    Fullscreen</Button> &nbsp;
                            </>}
                            <Button className="bottom-button" variant="outlined"
                                style={{ marginBottom: "20px" }}
                                onClick={() => {
                                    setReload(reload + 1);
                                    if (localStorage.getItem('musicData') !== null) {
                                        URL.revokeObjectURL(localStorage.getItem('musicData'))
                                    }
                                    let tmpC, tmpUP, tmpU, tmpR, tmpS;
                                    if (localStorage.getItem('cookie-notice') !== null) {
                                        tmpC = localStorage.getItem('cookie-notice');
                                    }
                                    if (localStorage.getItem('u_picture') !== null) {
                                        tmpUP = localStorage.getItem('u_picture');
                                    }
                                    if (localStorage.getItem('user') !== null) {
                                        tmpU = localStorage.getItem('user');
                                    }
                                    if (localStorage.getItem('u_role') !== null) {
                                        tmpR = localStorage.getItem('u_role');
                                    }
                                    if (localStorage.getItem('settings') !== null) {
                                        tmpS = localStorage.getItem('settings');
                                    }
                                    localStorage.clear();
                                    if (tmpC !== undefined) {
                                        localStorage.setItem('cookie-notice', tmpC);
                                    }
                                    if (tmpUP !== undefined) {
                                        localStorage.setItem('u_picture', tmpUP);
                                    }
                                    if (tmpU !== undefined) {
                                        localStorage.setItem('user', tmpU);
                                    }
                                    if (tmpR !== undefined) {
                                        localStorage.setItem('u_role', tmpR);
                                    }
                                    if (tmpS !== undefined) {
                                        localStorage.setItem('settings', tmpS);
                                    }
                                }}> Reset Visualizer</Button>
                        </div>
                        <p>Info:&nbsp;In&nbsp;Fullscreen:&nbsp;Press&nbsp;"P"&nbsp;to&nbsp;record PNG, "W" to record
                            webm and "S" to stop</p>
                        {(localStorage.getItem('micOrSound') === null || localStorage.getItem('micOrSound') === "mic" || localStorage.getItem('micOrSound') === "compareInputs") &&
                            <p>Info:&nbsp;Press&nbsp;on&nbsp;the&nbsp;canvas to freeze/unfreeze
                                diagram</p>}
                        {localStorage.getItem('micOrSound') === "sound" &&
                            <p>Info:&nbsp;Press&nbsp;on&nbsp;the&nbsp;canvas to play/pause</p>}
                        {(localStorage.getItem('micOrSound') === "toneGenerator" && localStorage.getItem('pinkNoise') !== "true") &&
                            <p>Info:&nbsp;Press&nbsp;on&nbsp;the&nbsp;canvas to create a tone</p>}
                        {(localStorage.getItem('micOrSound') === "toneGenerator" && localStorage.getItem('pinkNoise') === "true") &&
                            <p>Info:&nbsp;Press&nbsp;on&nbsp;the&nbsp;canvas to play/pause</p>}
                    </div>
                </div>
                <div className="big-container">
                    <div id="full-screen" style={{ minHeight: "40px" }}>
                        {aSettings && <>
                            {localStorage.getItem('micOrSound') !== "compareInputs" &&
                                <Button className="bottom-button" variant="outlined" style={{ marginBottom: "20px" }}
                                    onClick={() => {
                                        setReload(reload + 1);
                                        localStorage.setItem('micOrSound', "compareInputs");
                                    }}>
                                    Compare two audio sources
                                </Button>
                            }
                            &nbsp;
                            {(localStorage.getItem('micOrSound') !== "toneGenerator" || localStorage.getItem('pinkNoise') !== "true") &&
                                <Button className="bottom-button" variant="outlined" style={{ marginBottom: "20px" }}
                                    onClick={() => {
                                        setReload(reload + 1);
                                        localStorage.setItem('micOrSound', "toneGenerator");
                                        localStorage.setItem('pinkNoise', "true");
                                    }}>
                                    Generate Pink Noise
                                </Button>
                            }
                            &nbsp;
                            {(localStorage.getItem('micOrSound') === "toneGenerator" && localStorage.getItem('pinkNoise') !== "true") === false &&
                                < Button className="bottom-button" variant="outlined" style={{ marginBottom: "20px" }}
                                    onClick={() => {
                                        setReload(reload + 1);
                                        localStorage.setItem('micOrSound', "toneGenerator");
                                        localStorage.setItem('pinkNoise', "false");
                                    }}>
                                    Tone Generator
                                </Button>
                            }
                            &nbsp;
                            {localStorage.getItem('micOrSound') === "toneGenerator" && localStorage.getItem('pinkNoise') === "false" &&
                                <FreqSelect />
                            }
                        </>}
                        <MusicSelect key={"m" + reload} />
                        <MusicFromLink key={"reg" + reload} />
                        <SwitchMicMusic key={"s" + reload} />
                        {localStorage.getItem('micOrSound') === "sound" && <p>Song
                            name: {localStorage.getItem('musicName')}</p>}
                        {localStorage.getItem('micOrSound') === "compareInputs" && <><p>Info: This feature is
                            only
                            supported by the following browsers: <b>Chrome, Edge</b></p><p>
                                Info: {inputs[0]} input(s) found{Number(inputs[0]) > 1 && <>,
                                    using {inputs[1] !== '' ? inputs[1] : 'first'} and {inputs[2] !== '' ? inputs[2] : 'last'} for
                                    the comparison
                                </>}</p></>
                        }
                    </div>
                    <CheckError />
                </div>
                <div className="big-container" style={{ border: "none" }}>
                    <Selection key={"g" + reload} />
                </div>
            </div>
        );
    } else {
        return <div style={{ color: "white", textAlign: "center", marginTop: "49vh" }}>
            <h2>Battery Saver active, click <a href="/">here</a> to reload</h2>
        </div>
    }
}

export default MainApp;
