import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Slider from '@mui/material/Slider';
import Box from "@mui/material/Box";


const Attack = (style) => {


    const handleAttack = (value) => {
        if (localStorage.getItem('attack') !== value) {
            localStorage.setItem('attack', value);
        }
    };

    const marks = [
        {
            value: 0,
            label: '0',
        },
        {
            value: 1,
            label: '1',
        },
    ];

    return (
        <FormControl className={"all-form-inputs"} sx={style}>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                    Attack
                </InputLabel>
                <Slider style={{ marginTop: "24px" }}
                    getAriaValueText={handleAttack}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    step={0.01}
                    marks={marks}
                    min={0}
                    max={0.99}
                    defaultValue={localStorage.getItem('attack') === null ? 0.8 : localStorage.getItem('attack')}
                />
            </Box>
        </FormControl>
    );
};


export default Attack;