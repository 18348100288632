import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Slider from '@mui/material/Slider';
import Box from "@mui/material/Box";


const Clipping = (style) => {


    const handleClipping = (value) => {
        if (localStorage.getItem('clipping') !== value && value !== 0) {
            localStorage.setItem('clipping', value);
        }
    }

    const marks = [
        {
            value: 0,
            label: '0',
        },
        {
            value: 200,
            label: '200',
        },
    ];

    return (
        <FormControl className={"all-form-inputs"} sx={style}>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                    Clipping
                </InputLabel>
                <Slider style={{ marginTop: "24px" }}
                    getAriaValueText={handleClipping}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    marks={marks}
                    min={0}
                    max={200}
                    defaultValue={localStorage.getItem('clipping') === null ? 0 : localStorage.getItem('clipping')}
                />
            </Box>
        </FormControl>
    );
};


export default Clipping;