import { Button } from "@mui/material";
import { useState } from "react";
import { createSettings } from "../helpers";


const TopSelection = () => {

    const [text, setText] = useState("Save Settings as Link");
    const [sText, setSText] = useState(localStorage.getItem('settings') === "Advanced" ? "Simple" : "Advanced");

    const saveToClipboard = () => {
        navigator.clipboard.writeText("http://soundvisualiser.com/#/visualiser?settings=" + createSettings()).then(() => {
            setText("Copied!");
            setTimeout(() => { setText("Save Settings as Link") }, 1500);
        })
    }

    const changeSettingsType = () => {
        if (localStorage.getItem('u_role') === "advanced") {
            if (sText === "Advanced") {
                setSText("Simple");
                localStorage.setItem('settings', 'Advanced');
                global.refreshCanvas();
            } else {
                setSText("Advanced");
                localStorage.setItem('settings', 'Simple');
                global.refreshCanvas();
            }
        }
        else {
            window.location.href = "/subscription/index.html";
        }

    }

    return (
        <>
            <Button className="bottom-button" variant="outlined" onClick={saveToClipboard}>{text}</Button>&nbsp;
            <Button className="bottom-button" style={{ fontWeight: sText === "Advanced" ? 700 : 300 }} variant={sText === "Advanced" ? 'contained' : 'outlined'} onClick={changeSettingsType}>Show {sText} Settings</Button>&nbsp;

            <a href="/subscription/index.html"><Button className="bottom-button" variant="outlined">
                {localStorage.getItem('user') === null
                    ? 'Login' :
                    <>Account <img src={localStorage.getItem('u_picture')} style={{ height: "25px", marginLeft: "10px", marginRight: "-10px", borderRadius: "50px" }} alt={localStorage.getItem('user')} /></>
                }</Button></a>

        </>
    )
}

export default TopSelection;