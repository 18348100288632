import React, { useEffect, useState } from 'react'
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import useWindowDimensions from "../helpers";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import { isFirefox, isMobile } from 'react-device-detect';
import SvgIcon from '@mui/material/SvgIcon';
import LandingPageWave from '../landingpageWave';
import { styled } from "@mui/material/styles";


const LandingPage = () => {
    const [y, setY] = useState(null);
    const box = React.createRef();
    const { width } = useWindowDimensions();
    const checkWidth = width > 999;

    useEffect(() => {
        if (!box || isMobile) return;
        const pos = box.current?.offsetTop;
        const height = box.current?.offsetHeight;
        if (y > pos && y < pos + height) {
            const sliderPos = Math.round((y - pos) / height * 100);
            localStorage.setItem('height', String(sliderPos));
        }
    }, [y]);


    const handleHeight = (value) => {
        if (localStorage.getItem('height') !== String(100 - value)) {
            localStorage.setItem('height', String(100 - value));
        }
    };

    const RoundedDash = () => (
        <SvgIcon viewBox="0 0 10 10" sx={{ transform: "scale(1.2)" }}>
            <line x1="2.2" y1="5.2" x2="7.8" y2="5.2" stroke="#acb3b9"
                strokeLinecap="round" />
        </SvgIcon>
    )


    const CustomSlider = styled(Slider)(({ theme }) => ({
        color: "#8f5024",
        width: 7,
        '&.Mui-disabled': {
            color: "#8f5024",
        },
        '& .MuiSlider-thumb': {
            color: "#8f5024",
            height: 30,
            width: 30,
        },
        '& .MuiSlider-rail': {
            color: "#8f5024",
        },
        '& .MuiSlider-track': {
            color: "#8f5024",
        },
        '& .Mui-active': {
            color: "#8f5024",
            backgroundColor: "#8f5024",
        },
    }));

    const CustomButton = styled(Button)({
        backgroundColor: '#c36522',
        '&:hover': {
            backgroundColor: '#984f1b',
        },
        '&:active': {
            backgroundColor: '#c36522',
        },
    });

    return (
        <div style={{
            backgroundColor: "#0f0e0e", position: "fixed",
            padding: 0,
            margin: 0,
            top: 0,
            left: 0, width: "100%",
            height: "100%"
        }}>
            {!isMobile &&
                <div style={{
                    position: "fixed",
                    padding: 0,
                    margin: 0,
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                }}
                    onMouseMove={event => setY(event.pageY)}></div>
            }
            <Grid container spacing={2} align="center" sx={{ height: "100vh", paddingX: 0 }}>
                <Grid item xs={checkWidth ? 6 : 12} justify="center" sx={{ marginY: "auto" }}>
                    <Container sx={{ maxWidth: "650px!important" }}>
                        <Typography variant="h2" component="div" sx={{ fontWeight: 700 }}>Microphone and Music Visualizer</Typography>
                        <Typography variant="h4" sx={{ fontWeight: 400, marginY: "calc(6vh + 10px)" }}>
                            Simple <RoundedDash /> Free <RoundedDash /> Forever </Typography>
                    </Container>
                    <Link to="./visualiser" style={{ textDecoration: "none" }}>
                        <CustomButton variant="contained" size="large" onClick={() => localStorage.setItem('height', 90)} color="primary"
                            onMouseMove={event => !isMobile && setY(event.pageY)}
                            sx={{ marginY: 3, width: 350, paddingY: 1.5, borderRadius: 15 }}>
                            Open Visualizer
                        </CustomButton>
                    </Link>
                </Grid>
                {checkWidth &&
                    <Grid item xs={6} sx={{ marginY: "auto" }}>
                        <Grid container>
                            <Grid item xs={1}>
                                <Box sx={{ minHeight: "350px", height: "45vh", marginTop: "4.5vh" }} ref={box}>
                                    <CustomSlider
                                        sx={{
                                            '& input[type="range"]': {
                                                WebkitAppearance: 'slider-vertical',
                                            },
                                        }}
                                        orientation="vertical"
                                        defaultValue={localStorage.getItem('height') === null ? 100 : 100 - localStorage.getItem('height')}
                                        aria-label="Height"
                                        getAriaValueText={handleHeight}
                                        valueLabelDisplay="off"
                                        disabled={!isMobile}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <iframe id="iframe1" title="visualizer" src="/visualizer/LandingPageVis.html"
                                    style={{ height: "50vh" }} />
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
            <div className={`decorative-wave ${isFirefox ? 'ff-bottom' : 'bottom'}`} onMouseMove={event => !isMobile && setY(event.pageY)}><LandingPageWave /></div>
            <div className={`decorative-wave ${isFirefox ? 'ff-top' : 'top'}`} onMouseMove={event => !isMobile && setY(event.pageY)}><LandingPageWave /></div>
        </div>
    )
};

export default LandingPage;