import React, { useState } from 'react'
import { getYtLink } from "./service";


const MusicFromLink = () => {

    const [loading, setLoading] = useState(false);

    function youtube_parser(url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : false;
    }

    const manageChange = (e) => {
        const link = e.target.value;
        if (link.length > 15 && youtube_parser(link) !== false) {
            const id = youtube_parser(link);
            setLoading(true);
            getYtLink(id, (err, url) => {
                setLoading(false);
                if (err) {
                    localStorage.setItem("error", err.message);
                    global.refreshCanvas();
                } else {
                    localStorage.setItem('micOrSound', 'sound');
                    localStorage.setItem('musicName', url.title);
                    localStorage.setItem("ytlink", url.mp3);
                    global.refreshCanvas();
                }
            });
        }
    };

    return <></>; //This feature doesn't work anymore
    /*
        return (
            <label className="right-when-large" style={{marginBottom: "20px"}}>
                <TextField id="outlined-basic" style={{marginBottom: "20px"}} className="bottom-button"
                           placeholder="YouTube Link" variant="outlined"
                           onChange={e => manageChange(e)}/>
                &nbsp;
                {loading &&
                <Box sx={{display: 'flex'}}>
                    <CircularProgress size={30}/>
                    &nbsp;
                </Box>
                }
            </label>
        )
     */
};

export default MusicFromLink;