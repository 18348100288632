import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const CookieNotice = () => {
    const [show, setShow] = React.useState(true);

    const url = window.location.href;
    if (!url.includes("visualiser") && !url.includes("main") && show) {
        setShow(false);
    }

    if (localStorage.getItem("cookie-notice") === null && show) {
        return (
            <div className="cookie-notice">
                <h3 style={{ margin: 0, paddingTop: 5 }}>Cookies</h3>
                <p style={{ textAlign: "justify", marginTop: 0 }}>We use cookies to improve your experience on our website. By continuing to use our website, you agree to our use of cookies</p>
                <Button variant="outlined" onClick={() => { localStorage.setItem("cookie-notice", "true"); setShow(false) }}>Got it!</Button>
                <Link to="/privacy-policy" className="cookie-notice-link">Privacy Policy</Link>
            </div>
        )
    } else return <></>
}


export default CookieNotice;