import React, { useState } from 'react'
import FormControl from '@mui/material/FormControl';
import Box from "@mui/material/Box";
import { WhiteTextField } from "../WhiteTextField";


const LineWeight = (style) => {

    const [lWeight, setLWeight] = useState(localStorage.getItem('lineWeight') === null ? 1 : localStorage.getItem('lineWeight'));

    const updateMargin = (e) => {
        try {
            if (e.target.value < 0) {
                setLWeight(0);
                localStorage.setItem('lineWeight', '0');
            } else if (e.target.value > 1000) {
                setLWeight(1000);
                localStorage.setItem('lineWeight', '1000');
            } else {
                setLWeight(parseInt(e.target.value));
                if (e.target.value === '') {
                    localStorage.setItem('lineWeight', '0');
                } else {
                    localStorage.setItem('lineWeight', '' + parseInt(e.target.value));
                }
            }
        } catch (e) {
            setLWeight(0);
            localStorage.setItem('lineWeight', '1');
        }
    };


    return (
        <FormControl sx={style}>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, minWidth: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <WhiteTextField className={"all-form-inputs"} variant="standard" type="number" id="standard-basic" label="Line Weight" value={lWeight}
                    onChange={updateMargin} />
            </Box>
        </FormControl>
    )
};

export default LineWeight;