import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from "@mui/material/Box";
import { WhiteTextField } from "../WhiteTextField";

const LineType = (style) => {
    const [diagram, setDiagram] = React.useState(getLS);

    function getLS() {
        if (localStorage.getItem('vis') === null) {
            return "none";
        } else if (localStorage.getItem('vis') === "line") {
            return "line";
        } else {
            return localStorage.getItem('vis');
        }

    }

    const handleDiagram = (event) => {
        setDiagram(event.target.value);
        localStorage.setItem('type', "line");
        switch (event.target.value) {
            case "line":
                localStorage.setItem('vis', "line");
                break;
            case "line_circle":
                localStorage.setItem('vis', "line_circle");
                break;
            case "colorful_circle":
                localStorage.setItem('vis', "colorful_circle");
                break;
            default:
                localStorage.setItem('vis', "line");
        }
        global.refreshCanvas();
    };

    return (
        <FormControl sx={style}>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, minWidth: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <WhiteTextField variant="standard"
                    id="outlined"
                    select
                    label="Line Diagrams"
                    value={diagram}
                    onChange={handleDiagram}
                    className={"all-form-inputs"}
                    sx={{marginTop: 2}}
                >
                    <MenuItem value={"line"}>Line</MenuItem>
                    <MenuItem value={"line_circle"}>Circle</MenuItem>
                    <MenuItem value={"colorful_circle"}>Colorful Circle</MenuItem>
                </WhiteTextField>
            </Box>
        </FormControl>
    );
};


export default LineType;