import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from "@mui/material/Box";
import { WhiteTextField } from "../WhiteTextField";

const BarType = (style) => {
    const [diagram, setDiagram] = React.useState(localStorage.getItem('vis') === null || localStorage.getItem('vis') === "bars" ? "bars" : localStorage.getItem('vis'));

    const handleDiagram = (event) => {
        setDiagram(event.target.value);
        localStorage.setItem('type', "bar");
        switch (event.target.value) {
            case "bars":
                localStorage.setItem('vis', "bars");
                break;
            case "doubleBars":
                localStorage.setItem('vis', "doubleBars");
                break;
            case "sidebars":
                localStorage.setItem('vis', "sidebars");
                break;
            case "bar_circle":
                localStorage.setItem('vis', "bar_circle");
                break;
            case "multiColor":
                localStorage.setItem('vis', "multiColor");
                break;
            default:
                localStorage.setItem('vis', "bars");
        }
        global.refreshCanvas();
    };

    return (
        <FormControl sx={style}>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, minWidth: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <WhiteTextField
                    className={"all-form-inputs"}
                    id="outlined"
                    select
                    label="Bar Diagrams"
                    variant="standard"
                    value={diagram}
                    onChange={handleDiagram}
                >
                    <MenuItem key={"bars"} value={"bars"}>Bars</MenuItem>
                    <MenuItem key={"doubleBars"} value={"doubleBars"}>Double Bars</MenuItem>
                    <MenuItem key={"sidebars"} value={"sidebars"}>Sidebars</MenuItem>
                    <MenuItem key={"bar_circle"} value={"bar_circle"}>Circle</MenuItem>
                    <MenuItem key={"multiColor"} value={"multiColor"}>Multi Color</MenuItem>
                </WhiteTextField>
            </Box>
        </FormControl>
    );
};


export default BarType;