import React, { useState } from 'react'
import { Link } from "react-router-dom";
import PwaModal from './PwaModal';
import Share from "./Share";

const Footer = () => {

    const [bS, setBS] = useState(localStorage.getItem("batteryS") !== null ? localStorage.getItem("batteryS") : "true");

    const Mailto = ({ mailto, label }) => {
        return (
            <Link
                className="a-footer"
                onClick={(e) => {
                    window.location = mailto;
                    e.preventDefault();
                }}
            >
                <p className="footer-p">{label}</p>
            </Link>
        );
    };

    return (
        <div className="footer">
            <div className="share-container">
                <h2 style={{ marginLeft: "32px" }}>Share Soundvisualiser</h2>
                <Share />
            </div>
            <div className="f-container">
                <h3 style={{ marginLeft: "0" }}>Resources</h3>
                <Link className="a-footer"
                    to="../main"><p className="footer-p">Remove footer</p></Link>
                <a className="a-footer"
                    href="/visualizer/index.html"
                    target="_blank"><p className="footer-p">Fullscreen visualizer</p></a>
                <Link className="a-footer" to="../terms"><p className="footer-p">Terms of Service</p></Link>
                <Link className="a-footer" to="../privacy-policy"><p className="footer-p">Privacy Policy</p></Link>
            </div>
            <div className="f-container">
                <h3 style={{ marginLeft: "0" }}>Download</h3>
                <PwaModal />
                <a className="a-footer"
                    href="https://support.mozilla.org/en-US/kb/create-desktop-shortcut-website"
                    target="_blank" rel="noreferrer"><p className="footer-p">Desktop</p></a>
            </div>
            <div className="f-container">
                <h3 style={{ marginLeft: "0" }}>Community</h3>
                <a className="a-footer"
                    href="https://reddit.com/r/SoundVisualization"
                    target="_blank" rel="noreferrer"><p className="footer-p">Reddit</p></a>
                <Mailto label="Give Feedback" mailto="mailto:soundvisualiser@gmail.com?subject=User Feedback&body=Hi Noel,%0D%0A%0D%0AI would like to give you some feedback.%0D%0A%0D%0AHere's something that caught my eye: %0D%0A●%0D%0A%0D%0A Here's something I like: %0D%0A●%0D%0A%0D%0A Here's something I don't like:%0D%0A●%0D%0A%0D%0A Cheers, " />
                <Mailto label="Contact" mailto="mailto:soundvisualiser@gmail.com?subject=Contact" />
            </div>
            <div className="f-container" style={{ maxWidth: "230px" }}>
                {bS === "true" ?
                    <>
                        <h3 style={{ marginLeft: "0" }}>Battery saver enabled</h3>
                        <p>
                            <b style={{ cursor: "pointer" }} onClick={() => {
                                setBS("false");
                                localStorage.setItem("batteryS", "false");
                            }}>Disable it
                            </b>
                            &nbsp;to keep the app active when the tab is hidden.
                        </p>
                    </>
                    :
                    <>
                        <h3 style={{ marginLeft: "0" }}>Battery saver disabled</h3>
                        <p>
                            <b style={{ cursor: "pointer" }} onClick={() => {
                                setBS("true");
                                localStorage.setItem("batteryS", "true");
                            }}>Enable it
                            </b>
                            &nbsp;to make the app inactive when the tab is hidden.
                        </p>
                    </>
                }
            </div>
        </div>
    )
};

export default Footer;