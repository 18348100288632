import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ColorPicker from 'material-ui-color-picker';
import Box from "@mui/material/Box";

const FillColor = () => {
    let color = localStorage.getItem('fillcolor') ?? '#ffffff';
    const vis = localStorage.getItem('vis') ?? 'bars';

    const aSettings = localStorage.getItem('settings') === "Advanced";

    const setColor = (newColor) => {
        if (newColor && localStorage.getItem('fillcolor') !== newColor) {
            localStorage.setItem('fillcolor', newColor);

            if (!aSettings) {
                localStorage.setItem('linecolor', newColor);
            }
        }
    };

    // Early return for specific visualization types
    if (vis === 'multiColor' || vis === 'colorful_circle') {
        return null;
    }

    return (
        <FormControl
            sx={{
                '& .MuiInput-underline:before': {
                    borderBottomColor: '#fff8!important', // Semi-transparent underline
                },
                '& .MuiInput-underline:after': {
                    borderBottomColor: '#747477!important', // Solid underline on hover
                },
                ':hover': {
                    '& .MuiInput-underline:before': {
                        borderBottomColor: '#747477!important', // Example hover style
                    },
                },
                margin: 1, // Example, adjust according to your ThemeContext structure
                minWidth: '120px',
            }}
            className={"all-form-inputs"}
        >
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, minWidth: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <InputLabel shrink id="demo-simple-select-placeholder-label-label" sx={{}}>
                    Fill Color
                </InputLabel>
                <ColorPicker
                    name='color'
                    defaultValue={color}
                    className={"all-form-inputs-without-margin"}
                    onChange={setColor}
                />
            </Box>
        </FormControl>
    );
};

export default FillColor;