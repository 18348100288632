import React, { useState } from 'react'
import FormControl from '@mui/material/FormControl';
import { WhiteTextField } from "../WhiteTextField";


const FreqSelect = (classes) => {

    const [freq, setFreq] = useState(localStorage.getItem('freq') === null ? 50 : localStorage.getItem('freq'));

    const updateFreq = (e) => {
        try {
            if (e.target.value > 9999) {
                setFreq(9999);
                localStorage.setItem('freq', '9999');
            } else {
                if (e.target.value === '' || e.target.value < 50) {
                    setFreq(e.target.value)
                } else {
                    setFreq(parseInt(e.target.value));
                    localStorage.setItem('freq', '' + parseInt(e.target.value));
                }
            }
        } catch (e) {
            setFreq(0);
            localStorage.setItem('freq', '0');
        }
    };

    const changeText = () => {
        if (freq === '' || freq < 50) {
            setFreq(50);
            localStorage.setItem('freq', '50');
        }
    };

    return (
        <FormControl className={"freq " + classes.formControl}>
            <WhiteTextField variant="standard" type="number" id="outlined-number" label="Frequency" value={freq}
                onChange={updateFreq}
                InputLabelProps={{ shrink: true, }} onBlur={changeText} />
        </FormControl>
    );
};

export default FreqSelect;