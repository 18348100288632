import React, { useState } from 'react'

const BehindCanvas = () => {
    const [percent, setPercent] = useState("");

    window.addEventListener("storage", () => {
        if (localStorage.getItem("percentLoaded") !== null) {
            setPercent(localStorage.getItem("percentLoaded"));
        }
    });

    if (localStorage.getItem("percentLoaded") !== null) {
        return (
            <div className="troubleshooting">
                Loaded {percent}%
            </div>
        )
    } else {
        return (
            <div className="troubleshooting">
                If the canvas doesn't load, try the following:
                <ol>
                    <li>Press Reload Canvas</li>
                    {localStorage.getItem('musicData') !== null ? <li><b>Select your sound file again</b></li> :
                        <li>Check microphone permissions for your browser in your OS's system</li>}
                    <li>Disable any ad or javascript blocker that might cause an interference</li>
                    <li>Press <b>Reset Visualizer</b></li>
                </ol>
            </div>
        )
    }
};

export default BehindCanvas;