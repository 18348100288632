import React from 'react'
import {
    EmailShareButton,
    WhatsappShareButton,
    RedditShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TumblrShareButton,
    HatenaShareButton,
    FacebookShareButton,
    FacebookMessengerShareButton,
    TwitterShareButton
} from "react-share";


import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    LinkedinIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    WhatsappIcon,
    TwitterIcon
} from "react-share";

const Share = () => {
    const shareUrl = 'https://soundvisualiser.com';

    return (
        <div style={{ margin: "20px" }}>
            <EmailShareButton url={shareUrl}><EmailIcon size={42} round={true} /></EmailShareButton>
            <FacebookShareButton url={shareUrl}><FacebookIcon size={42} round={true} /></FacebookShareButton>
            <FacebookMessengerShareButton url={shareUrl}><FacebookMessengerIcon size={42}
                round={true} /></FacebookMessengerShareButton>
            <TwitterShareButton url={shareUrl}><TwitterIcon size={42} round={true} /></TwitterShareButton>
            <WhatsappShareButton url={shareUrl}><WhatsappIcon size={42} round={true} /></WhatsappShareButton>
            <RedditShareButton url={shareUrl}><RedditIcon size={42} round={true} /></RedditShareButton>
            <LinkedinShareButton url={shareUrl}><LinkedinIcon size={42} round={true} /></LinkedinShareButton>
            <TelegramShareButton url={shareUrl}><TelegramIcon size={42} round={true} /></TelegramShareButton>
            <TumblrShareButton url={shareUrl}><TumblrIcon size={42} round={true} /></TumblrShareButton>
            <HatenaShareButton url={shareUrl}><HatenaIcon size={42} round={true} /></HatenaShareButton>

        </div>
    )
};

export default Share;
