import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ColorPicker from 'material-ui-color-picker';
import Box from "@mui/material/Box";

const LineColor = () => {
    let color = localStorage.getItem('linecolor') ?? '#ffffff';

    const setColor = (newColor) => {
        if (newColor !== undefined && localStorage.getItem('linecolor') !== newColor) {
            localStorage.setItem('linecolor', newColor);
        }
    };

    return (
        <FormControl
            sx={{
                '& .MuiInput-underline:before': {
                    borderBottomColor: '#fff8!important', // Semi-transparent underline
                },
                '& .MuiInput-underline:after': {
                    borderBottomColor: '#747477!important', // Solid underline on hover
                },
                ':hover': {
                    // Hover styles for FormControl
                    '& .MuiInput-underline:before': {
                        borderBottomColor: '#747477!important', // Example hover style
                    },
                },
                margin: 1, // Example, adjust according to your ThemeContext structure
                minWidth: '120px',
            }}
            className={"all-form-inputs"}
        >
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, minWidth: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <InputLabel
                    shrink
                    id="demo-simple-select-placeholder-label-label"
                    sx={{
                        // Styles for InputLabel if needed
                        // Example: color: 'text.primary',
                    }}
                >
                    Line Color
                </InputLabel>
                <ColorPicker
                    name='color'
                    defaultValue={color}
                    className={"all-form-inputs-without-margin"}
                    onChange={setColor}
                />
            </Box>
        </FormControl>
    );
};

export default LineColor;