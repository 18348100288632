import React, { useState } from "react"
import Slider from "@mui/material/Slider";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Record from "./Record";


const AudioPlayer = () => {
    const [endTime, setEndTime] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);

    const [checked, setChecked] = useState(localStorage.getItem("startOnZero") === null ? false : localStorage.getItem("startOnZero"));


    window.addEventListener("storage", () => {
        if (localStorage.getItem('currentTime') !== currentTime) {
            setCurrentTime(Number(localStorage.getItem('currentTime')));
        }
        if (localStorage.getItem('duration') !== endTime) {
            setEndTime(Number(localStorage.getItem('duration')));
        }
    });

    const isDisabled = () => {
        return localStorage.getItem("micOrSound") !== "sound";
    };

    const formatSmol = (num) => {
        if (num < 10) {
            return `0${num}`
        } else if (num < 60) {
            return `${num}`
        }
    };


    const formatToTime = (sec) => {
        if (sec === undefined) {
            sec = 0;
        }
        sec = Math.floor(sec);
        const min = Math.floor(sec / 60);
        sec = sec % 60;
        return `${formatSmol(min)}:${formatSmol(sec)}`
    };

    const handleChange = (event, newValue) => {
        localStorage.setItem("changeTime", newValue);
    };

    const handleCheckboxChange = (event) => {
        localStorage.setItem("startOnZero", event.target.checked);
        setChecked(event.target.checked);
    };

    return (
        <div style={{ margin: "0 40px 20px 40px", paddingBottom: "15px", marginTop: "-25px" }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                    <div className="box-shadowy">
                        <Grid container spacing={3} alignItems="center">
                            <Grid item>
                                {formatToTime(currentTime)}
                            </Grid>
                            <Grid item xs>
                                <Slider
                                    className="time-slider"
                                    aria-label="Time"
                                    disabled={isDisabled()}
                                    value={currentTime}
                                    max={endTime}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item>
                                {formatToTime(endTime)}
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item>
                    <FormControlLabel control={<Checkbox color={"primary"} checked={checked} disabled={isDisabled()} />}
                        label="Start on zero" onChange={handleCheckboxChange} />
                </Grid>
                <Grid item xs className="record">
                    <Record />
                </Grid>
            </Grid>
        </div>
    )
};

export default AudioPlayer;