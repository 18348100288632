import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import MainApp from "./MainApp";
import Error from "./components/Error";
import Footer from "./footer/Footer";
import { getYtLink } from "./components/service";
import LandingPage from "./components/LandingPage";
import { unpackSettings } from './helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import CookieNotice from './components/CookieNotice';
import { createTheme, ThemeProvider } from '@mui/material/styles';



const App = () => {

    var firebaseConfig = {
        apiKey: "AIzaSyC5sPU61Wb6ifX7boVXarrTf4QCuBsLWPY",
        authDomain: "audio-vis.firebaseapp.com",
        databaseURL: "https://audio-vis-default-rtdb.firebaseio.com",
        projectId: "audio-vis",
        storageBucket: "audio-vis.appspot.com",
        messagingSenderId: "1017980115013",
        appId: "1:1017980115013:web:6e459dfa9e7f40a8c82b2c",
        measurementId: "G-SZRTGG84B8"
    };

    // Initialize Firebase
    if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig);
    }

    // Initialize Analytics and get a reference to the service
    firebase.analytics();
    const db = firebase.firestore();


    useEffect(() => {
        try {
            if (localStorage.getItem('user') === null || localStorage.getItem('user') === undefined) {
                firebase.auth().onAuthStateChanged((user) => {
                    if (user) {
                        // User is signed in, see docs for a list of available properties
                        // https://firebase.google.com/docs/reference/js/firebase.User
                        var uid = user.uid;
                        console.log(user);
                        localStorage.setItem('user', user.displayName === null ? user.email : user.displayName)
                        localStorage.setItem('u_picture', user.photoURL);

                        if (localStorage.getItem('u_role') !== "advanced") {
                            console.log('Fetching user data...');
                            db.collection('customers')
                                .doc(uid)
                                .collection('payments')
                                .onSnapshot(async (snapshot) => {
                                    if (!snapshot.empty) {
                                        localStorage.setItem('u_role', "advanced");
                                    }
                                });
                        }
                    } else {
                        console.log('User is signed out');
                        localStorage.removeItem('user');
                    }
                })
            }
        } catch (e) {
            console.log(e);
        }
    }, []);

    const FullMainApp = () => {
        const { search } = useLocation();
        let navigate = useNavigate();
        const queryParams = new URLSearchParams(search, [search]);
        const v = queryParams.get('v');


        getYtLink(v, (err, url) => {
            if (err) {
                localStorage.setItem("error", err.message);
                global.refreshCanvas();
                window.history.pushState({}, document.title, "/");
            }
            if (url !== undefined) {
                localStorage.setItem('micOrSound', 'sound');
                localStorage.setItem('musicName', url.title);
                localStorage.setItem("ytlink", url.mp3);
                window.history.pushState({}, document.title, "/");
                global.refreshCanvas();
            }
        });


        const settings = queryParams.get('settings');
        if (settings !== null && settings !== undefined && settings.length > 9) {
            unpackSettings(settings);
            navigate("/visualiser");
            setTimeout(() => { try { global.refreshCanvas(); } catch (e) { } }, 150);
        }



        return (
            <>
                <MainApp />
                <Footer />
            </>
        )
    };

    const ToS = () => {
        return <iframe title="terms and conditions" className='no-style' src="/terms.html" />
    }
    const Pp = () => {
        return <iframe title="privacy policy" className='no-style' src="/privacy.html" />
    }


    const theme = createTheme({
        palette: {
            mode: 'dark',
        },

        overrides: {
            MuiOutlinedInput: {
                root: {
                    position: "relative",
                    "& $notchedOutline": {
                        borderColor: "#FFFFFF"
                    },
                    "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                        borderColor: "#FFFFFF",
                        "@media (hover: none)": {
                            borderColor: "#FFFFFF"
                        }
                    },
                    "&$focused $notchedOutline": {
                        borderColor: "#FFFFFF",
                        borderWidth: 1
                    }
                }
            }
        }
    });

    return (
        <main>
            <ThemeProvider theme={theme}>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/visualiser" element={<FullMainApp />} />
                    <Route path="/main" element={<MainApp />} />
                    <Route path="/terms" element={<ToS />} />
                    <Route path="/privacy-policy" element={<Pp />} />
                    <Route path="/:error" element={<Error />} />
                    <Route element={<Error />} />
                </Routes>
                <CookieNotice />
            </ThemeProvider>
        </main>

    );
}

export default App;